import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { getPrebuildRoutes } from '@/helpers/getRoutes';
import Template from '@/templates';
import NoozNextApi from '@/utils/services/NoozNextApi';
import getPageProps from '@/helpers/getPageProps';
import sanitizeObject from '@/helpers/sanitizeObject';
import replaceUndefinedWithNull from '@/helpers/replaceUndefinedWithNull';
import { HandlesPageProps, PageProps } from '@/interface.custom';
import DataLayerHelper from '@/helpers/Datalayer';
import LocaleHelper from '@/helpers/Locale';
import sizeof from '@/helpers/sizeof';
import { pageIsInvalid } from '@/utils';
import { PageSeo } from '@/components/pages/Account/PageSeo';

const Handles: NextPage<HandlesPageProps> = (props) => {
  // @ts-ignore
  useEffect(() => {
    NoozNextApi.setLocaleHeaders(props.locale);
    if (typeof window !== 'undefined') {
      DataLayerHelper.pushDataLayerEvent('VirtualPageview', {
        currency: 'EUR',
        // @ts-ignore
        currentPage: props?.page?.fields.title,
        // @ts-ignore
        currentPagetype: props?.page?.fields.pageType.type,
      });
    }
  }, []);

  return (
    <PageSeo>
      <Template
        templateKey={
          (props as unknown as PageProps['props']).page?.fields?.pageType
            .type as TemplateKey
        }
      />
    </PageSeo>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = await getPrebuildRoutes();
  return {
    paths:
      process.env.SKIP_BUILD_STATIC_GENERATION === 'true' ? [] : [...paths],
    fallback: 'blocking',
  };
};

// @ts-ignore
export const getStaticProps: GetStaticProps = async ({ params }) => {
  const { handles, locale } = params as NextParsedUrlQuery;
  const path = ((handles || ['']) as string[]).join('/'),
    contentfulLocale = LocaleHelper.getValidContentfulLocale(locale as string);
  let ids: null | { slugId: string; pageId: string } = null;

  try {
    if (/^404/.test(path) || /^500/.test(path)) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('error page');
    }
    LocaleHelper.checkLocaleSupported(locale as string);
    {
      await import(`@/json/routes/${contentfulLocale}.json`)
        .then((res) => {
          ids = res.default[path];
          return;
        })
        .catch(() => ({}));
    }

    // url is in english ?
    if (!ids) {
      await import(`@/json/routes/${contentfulLocale}.json`)
        .then((res) => {
          ids = res.default[path];
          return;
        })
        .catch(() => ({}));
    }

    if (!ids) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('no slug matching');
    }
  } catch (e: any) {
    return { notFound: true };
  }
  try {
    let newProps = await getPageProps(ids, locale as string, contentfulLocale);

    if (pageIsInvalid(newProps, locale as string)) {
      return { notFound: true };
    }

    if (process.env.NODE_ENV === 'development') {
      const obj = replaceUndefinedWithNull(sanitizeObject(newProps)).props;
      console.log('==>', sizeof(obj, true));
      for (const key of Object.keys(obj)) {
        console.log('==>', key, sizeof(obj[key as any], true));
      }
    }
    // inject json shopItems into shopstory
    if (
      ['Home', 'Shopstory'].includes(
        (newProps.props?.page.fields?.pageType as any).type || '',
      )
    ) {
      for (const resources of newProps.props?.shopstory?.meta?.resources ||
        []) {
        switch ((resources.values?.default as any)?.fields?.key) {
          case 'facetsList':
            if ((resources.values?.default as any)?.fields.items)
              for (
                let i = 0;
                i < ((resources.values?.default as any)?.fields.items).length;
                ++i
              ) {
                try {
                  (resources.values?.default as any).fields.items[i].shopitems =
                    (
                      await import(
                        `@/json/shopitems/shopstory/${
                          ((resources.values as any).default as any)?.fields
                            .items[i].sys.id
                        }-${locale}.json`
                      )
                    )?.default;
                } catch (e) {
                  console.error(
                    `\n${
                      (e as Error).message
                    }\nErreur chargement shopstory facetsList @/json/shopitems/shopstory/${
                      (resources.values?.default as any).fields.items[i].sys.id
                    }-${locale}.json`,
                  );
                  (resources.values?.default as any).fields.items[i].shopitems =
                    {
                      quantity: 0,
                      items: [],
                    };
                }
              }
            break;
          case 'facets':
            try {
              (resources.values?.default as any).shopitems = (
                await import(
                  `@/json/shopitems/shopstory/${
                    (resources.values?.default as any).sys.id
                  }-${locale}.json`
                )
              )?.default;
            } catch (e) {
              console.error(
                (e as Error).message,
                `\n${(e as Error).message}\nErreur chargement shopstory facets
                  ../json/shopitems/shopstory/${
                    (resources.values?.default as any).sys.id
                  }-${locale}.json`,
              );
              (resources.values?.default as any).shopitems = {
                quantity: 0,
                items: [],
              };
            }
            break;
          default:
            break;
        }
      }
    }
    return replaceUndefinedWithNull(sanitizeObject(newProps));
  } catch (e) {
    console.error(`\nERROR <getStaticProps> PageInfo[${path}][${locale}]`);
    // @ts-ignore
    console.error(e?.message);
    throw e;
  }
};

export default Handles;
